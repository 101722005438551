import React, { Component } from 'react';

class ElaySlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [
        'one-fruits.jpg',
        'two-vegetables.jpg',
        'three-pulses.jpg',
        'four-textile.jpg',
        'five-suit.jpg',
        'six-dress.jpg',
        'seven-jumper.jpg',
        'eight-jeans.jpg',
        'nine-freezer.jpg',
        'ten-washing-machine.jpg',
        'eleven-television.jpg',
        'twelve-computer.jpg'
      ],
      info: [],
      currentIndex: 0,
      windowHeight: window.innerHeight,
    };
  }

  componentDidMount() {
    this.startAutoSlider();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    this.stopAutoSlider();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
    });
  };

  startAutoSlider = () => {
    this.intervalId = setInterval(() => {
      this.setState((prevState) => ({
        currentIndex:
          prevState.currentIndex === prevState.images.length - 1
            ? 0
            : prevState.currentIndex + 1,
      }));
    }, 3000);
  };

  stopAutoSlider = () => {
    clearInterval(this.intervalId);
  };

  handlePrev = () => {
    this.stopAutoSlider();
    this.setState((prevState) => ({
      currentIndex:
        prevState.currentIndex === 0
          ? prevState.images.length - 1
          : prevState.currentIndex - 1,
    }));
  };

  handleNext = () => {
    this.stopAutoSlider();
    this.setState((prevState) => ({
      currentIndex:
        prevState.currentIndex === prevState.images.length - 1
          ? 0
          : prevState.currentIndex + 1,
    }));
  };

  render() {
    const { images, currentIndex, windowHeight } = this.state;
    const imageUrl = images[currentIndex];
    
    return (
      <div style={{ position: 'relative', display:'flex', flexWrap:'nowrap', justifyContent:'center', alignItems:'center', maxHeight:{windowHeight}, padding:20}}>
        <img
          src={imageUrl}
          alt="Photo"
          style={{ width: '90%',
          height: '25rem',
          maxHeight: windowHeight,
          objectFit: 'cover' }}
        />
      </div>
    );
  }
}

export default ElaySlider;
