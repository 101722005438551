import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById("scroll-top").classList.add("show")
      : document.getElementById("scroll-top").classList.remove("show");
  }
  componentWillUnmount() {
    this.mount = false;
  }
  render() {
    return (
      <div>
        <div className="footer-area" style={{backgroundColor:'black'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="footer-content-wrapper section-space--inner--100">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-10">
                      <div className="footer-intro-wrapper">
                        <div className="footer-logo">
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 offset-xl-1 col-lg-12 col-md-6">
                      <div className="footer-widget">
                        <h4 className="footer-widget__title" style={{color:'#82a5c3', textAlign:'center', fontSize:'50px'}}><u>SOCIAL TOOLS</u></h4>
                        <ul className="footer-widget__navigation">
                          <li>
                            <p style={{color:'white', fontSize:'30px', textAlign:'center'}}>• Contact Us Via e-Mail •</p>
                            <p style={{textAlign:'center'}}><a href="mailto:info@elay.me"><p style={{fontSize:'30px'}}>info@elay.me</p></a></p>
                          </li>
                          <br/>
                          <li>
                            <p style={{color:'white', fontSize:'30px', textAlign:'center'}}>• Contact Us On WhatsApp •</p>
                            <p style={{textAlign:'center'}}><a href="https://wa.me/971503997233"><p style={{fontSize:'30px'}}>+(97) 150 399 72 33</p></a></p>
                          </li>
                          <br/>
                          <li>
                            <p style={{color:'white', fontSize:'30px', textAlign:'center'}}>• Company Address •</p>
                            <p style={{textAlign:'center'}}><a href='#'><p style={{fontSize:'20px'}}>Biz Space Business Center City<br/> Avenue Building 7th Floor<br /> Office 706-708<br/> Unit 13 Deira - Dubai<br/> United Arab Emirates</p></a></p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright-wrapper">
            <div className="footer-copyright text-center">
              <p className="copyright-text">
              &copy; {new Date().getFullYear()} ELAY Trade L.L.C. F-Z | All Rights Reserved |
              </p>
            </div>
          </div>
        </div>
        <button
          className="scroll-top"
          id="scroll-top"
          onClick={this.scrollToTop}
        >
          <i className="ion-android-arrow-up" />
        </button>
      </div>
    );
  }
}
export default Footer;