import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ElaySlider from '../components/ElaySlider';

class Home extends Component{
    render(){
      
        return(
            <div>
                <NavBar/>
                
              <div style={{backgroundColor:'black'}}>
              <div className="info-container" style={{backgroundColor:'#82a5c3'}}>
                <div className="info-section">
                <ElaySlider/>
                <hr/>
                <h1 style={{color:'black', fontSize:'30px', textAlign:'center'}}>The Services Provided by ELAY Trading L.L.C. F-Z Company.</h1>
                <hr />
                  <h1 id='foods' style={{textAlign:'center'}}>FOODS</h1>
                  <hr/>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Organic Products:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>For those embracing a healthy and natural lifestyle, discover the freshest and highest quality products in our organic food category. Embark on a special journey from the field to your table, strengthening your dietary habits with our organic offerings and savoring the taste of nature.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Local Flavors:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>For those who respect local tastes, we offer a special selection. Explore exquisite flavors from all corners of Turkey. With our food products crafted by local producers, bring cultural richness to your table and experience the true joy of flavors.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Gluten-Free Alternatives:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Explore healthy and delicious alternatives with our specially selected products for those with gluten sensitivity. Enrich your gluten-free diet with snacks, bakery goods, and more. Healthy eating has never been this enjoyable!</li>
                    <br/>
                  </ul>
                  </div>
              </div>
              <div className="info-section">
                <hr/>
                  <h1 id='textiles' style={{textAlign:'center'}}>TEXTILES</h1>
                  <hr/>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Sustainable Fashion:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Embracing sustainability in the world of fashion, our website awaits you with carefully selected sustainable textile products. Elevate your style to a sustainable future with environmentally friendly, high-quality, and fashion-forward products.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Seasonal Trends:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Would you like to discover the latest fashion trends every season? On our website, you can find the newest and most popular designs among our exclusive textile products, allowing you to update your wardrobe with the standout styles of the season.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Comfort and Elegance:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our textile collection combines comfort and elegance in perfect balance. Crafted with high-quality fabrics and unique designs, our products create a perfect style for you by offering both comfort and elegance.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Ethnic Textures:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Discover textile products that reflect global diversity and ethnic cultures. Our website's ethnic textures add a unique touch to your style, designed with inspiration from different cultures.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Custom Designs:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>If you're looking for custom designs that reflect your personal style, you're in the right place. Our special collection on the website impresses you with unique and original designs, making every moment special.</li>
                    <br/>
                  </ul>
                  </div>
              </div>
              <div className="info-section">
                <hr/>
                  <h1 id='eitems' style={{textAlign:'center'}}>ELECTRICAL ITEMS</h1>
                  <hr/>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Technological Innovation:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Explore the electronic devices category on our website to discover the latest technology products and innovative devices. Our products, designed with cutting-edge technology, stand out with features that make your life easier. Experience technological innovation.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Entertainment World:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Transform your home into an entertainment center with our specially selected electronic products for entertainment enthusiasts. Enjoy moments filled with high-resolution visuals, crystal-clear sound, and state-of-the-art gaming equipment.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Smart Lifestyle:</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Make your life more comfortable with smart home technologies and wearable devices. Our electronic devices on the website are filled with products that support a smart lifestyle. Explore solutions for every need, from home automation to health tracking.</li>
                    <br/>
                  </ul>
                  </div>
                  <div className="info-section">
                <hr/>
                  <h1 id='aboutUs' style={{textAlign:'center'}}>ABOUT US</h1>
                  <hr/>
                  <div className='container'>
                  <h2 style={{textAlign:'left'}}><u>Who Are We?</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our company is a well-established entity in the industry, operating in the fields of food, textiles, and electrical materials. With a wealth of experience and an expert team, we maintain a leading position in the sector by focusing on quality products and customer satisfaction.</li>
                    <br/>
                  </ul>
                  <h2 style={{textAlign:'left'}}><u>Our Mission</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>To exceed customer expectations by offering the highest quality products and to become a reliable trading partner. We are committed to sustainability principles, aiming to make a positive impact on society and the environment.</li>
                    <br/>
                  </ul>
                  <h2 style={{textAlign:'left'}}><u>Our Vision</u></h2>
                  <ul>
                    <li style={{fontSize:'30px'}}>Our vision is to aspire to leadership in the industry by consistently innovating and developing solutions that add value to our customers. We aim to be one of the most respected brands in the sector, actively establishing our presence in the global market.</li>
                    <br/>
                  </ul>
                  </div>
                  </div>
              </div>
              </div>
              <Footer />
            </div>
            </div>
        )
    }
}
export default Home;