import React, { Component } from 'react';
import MobileMenu from './MobileMenu';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  }

  handleScroll() {
    const threshold = 100; // Belirli bir eşik değeri (px) belirleyin
    const scrollY = window.scrollY;

    if (scrollY > threshold) {
      this.setState({ isSticky: false });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div>
        <div className="header-area header-sticky header-sticky--default" style={{ backgroundColor: 'black' }}>
          <div className="header-area__desktop header-area__desktop--default">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="header-info-wrapper align-items-center">
                    <div className="logo" style={{ maxWidth: '100%', textAlign: 'center' }}>
                      {/* Logo */}
                      <img src="logo.png" alt="Logo" style={{ width: '70%', height: '6rem', objectFit:'cover' }} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mobile-navigation-icon" id="mobile-menu-trigger" onClick={this.activeMobileMenu}>
                    <i />
                  </div>
                </div>
              </div>
            </div>
            {/* Navigation */}
            <div className="header-navigation-area default-bg" style={{ backgroundColor: 'black' }}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                          {/* Menü öğeleri */}
                          <ul>
                            <li><a href='#foods' style={{ fontSize: '30px', color: '#82a5c3' }}>Foods</a></li>
                            <li style={{ color: 'white', fontSize: '50px' }}>|</li>
                            <li><a href='#textiles' style={{ fontSize: '30px', color: '#82a5c3' }}>Textiles</a></li>
                            <li style={{ color: 'white', fontSize: '50px' }}>|</li>
                            <li><a href='#eitems' style={{ fontSize: '30px', color: '#82a5c3' }}>Electrical Items</a></li>
                            <li style={{ color: 'white', fontSize: '50px' }}>|</li>
                            <li><a href='#aboutUs' style={{ fontSize: '30px', color: '#82a5c3' }}>About Us</a></li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menü */}
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    )
  }
}

export default NavBar;
