import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
class PageNotFound extends Component{
    render(){
        return(
            <div>
                <NavBar/>
                <div>
                    <div className="error-page-wrapper d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-3 col-md-9 m-auto text-center">
                            <div className="error-content-centered d-flex align-items-center justfy-content-center">
                            <div className="error-page-content-wrap">
                                <h2>404</h2>
                                <br/>
                                <h3>Page Not Found</h3>
                                <br/>
                                <a href="/home" className="ht-btn ht-btn--default ht-btn--default--dark-hover">Return Home Page</a>
                                <br/><br/>
                                <p>&copy; {new Date().getFullYear()} ELAY Trading L.L.C. F-Z  </p>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer/>
                <MobileMenu/>
            </div>
        )
    }
}
export default PageNotFound;